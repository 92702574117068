import React from 'react'

const List = (props) => {
   
  return (
    <>
    <div className="todo_style">
        <i className = "fa fa-times" aria-hidden = "true" onClick={()=>{
            props.onselect(props.id)
        }}></i>
    <li>{props.text}</li>
    </div>
   
    </>
    
  )
}

export default List