import { useState } from 'react';
import List from './List';
import './App.css'


function App() {
  const [inputlist, setinputlist] = useState("");
  const [Items, setitems] = useState([]);

  const itemadd = (e)=>{
   setinputlist(e.target.value)
  }
  const listchange = ()=>{
     setitems((olditems) =>{
      return [...olditems, inputlist];
     })
     setinputlist("")
  }
  const deleteitem = (id)=>{
  setitems((olditems)=>{
    return olditems.filter((arrele,index)=>{
   return  index  !== id;
    })
  })
}
  
  
  
  return (
    <div className="main_div">
   
    <div className="center_div">
      <br />
      <h1>ToDO List</h1>
      <br />
      <input type="text" placeholder="Add Items" onChange={itemadd} value={inputlist} />
      <button onClick={listchange}>+</button>

      <ol>
      {Items.map( (itemval,index) => {
        return <List key = {index} id = {index} text = {itemval} onselect = {deleteitem}/>
       })}
      </ol>
    </div>
    </div>
    
  );
}

export default App;